#interactive.viewport {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #2d2d2d;
}

#interactive.viewport > canvas {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
}

#interactive.viewport > canvas.drawing, #interactive.viewport > canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}

#interactive.viewport > video {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    justify-content: center;
    z-index: 1;
}

@media (max-width: 480px) {
    #interactive.viewport > canvas, #interactive.viewport > video {
        width: 100vw;
        height: 56.25vw; /* maintaining a 16:9 aspect ratio */
    }
}
