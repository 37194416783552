.notification-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .notification-table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 16px;
  }
  
  .notification-table th,
  .notification-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  

  
  
  
  .notification-table th {
    background-color: #3D4988;
    color: white;
  }
  
  .notification-table tr:hover {
    background-color: #ddd;
  }
  
  @media only screen and (max-width: 600px) {
    .notification-table {
      font-size: 14px;
    }
  }
  
  
  
  .modalContent {
    width: 50%;
    min-width: 300px;
    max-width: 600px;
  }
  
tr:hover {
  background-color: #ddd;
}
tr.created-today {
  background-color: green;
}
tr.created-today:hover {
  background-color: rgb(25, 197, 25);
}
tr.updated-today {
  background-color: rgb(7, 189, 140);
}
tr.updated-today:hover {
  background-color: rgb(17, 156, 163);
}