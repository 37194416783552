.input {
    padding: 0.5em;
    margin: 0.5em;
    color: palevioletred;
    background: papayawhip;
    border: none;
    border-radius: 3px;
  }
  .label {
    padding: 0.5em;
    margin: 0.5em;
    color: palevioletred;
    background: papayawhip;
    border: none;
    border-radius: 3px;
  }
  table {
    /* style the table element */
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    /* style the table cells */
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  th {
    /* style the table header cells */
    background-color: #dddddd;
  }
  
  tr:nth-child(even) {
    /* style the even rows */
    background-color: #f2f2f2;
  }
  
  .action-cell {
    /* style the action cells */
    text-align: center;
  }
  
  .floating-about-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}
.hidden{
   display: none;
}
.about-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  